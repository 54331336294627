.App {
  height: 100vh;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App > * {
  margin: 3px 0;
}

.header {
  text-align: center;
}

.header p {
  margin: 5px 0;
}

.code {
  padding: 5px;
  color: greenyellow;
  background-color: black;
  border-radius: 5px;
}

.content > img {
  width: 100%;
  max-width: 720px;
  max-height: 500px;
  border-radius: 10px;
}

.content {
  position: relative;
}

.content > canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  text-decoration: none;
  color: white;
  background-color: black;
  border: 2px solid black;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  color: black;
  background-color: white;
  border: 2px solid black;
}
